import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.scss'


class Subnavigation extends React.Component {
  render() {

    const projectTitle = this.props.projectTitle
    const projectSlug = this.props.projectSlug

    const projectPages = this.props.projectPages

    const currentPageSlug = this.props.currentPageSlug

    var subnavLinks = ''

    

    subnavLinks = projectPages.map((projectPage, i) => {
      let subnavSlug = `/${projectSlug}/${projectPage.slug}/`
      if(i == 0) {
        subnavSlug = `/${projectSlug}/`
      } 
      let subnavLink = <Link key={projectPage.slug} to={subnavSlug}>{projectPage.title}</Link>
      if(currentPageSlug == projectPage.slug) {
        subnavLink = <span>{projectPage.title}</span>
      }

        return <li key={projectPage.slug} className={styles.navigationItem}>{subnavLink}</li>
      
    })


  
  // var menuItems = ''


  //   var listItemsMax = menu.map((menuItem,i) => {
  //     let slug = '/'

  //     var linkCode = ''

  //     if (menuItem.pageItem) {
  //       slug = '/'+menuItem.pageItem.slug+'/'
  //       linkCode = <Link style={{color: 'black'}}activeStyle={{ fontWeight: "bold" }} to={slug}>{menuItem.title}</Link>
  //     }
  //     if (menuItem.link) {
  //       slug = menuItem.link
  //       var linkClass = slugify(menuItem.title)
  //       linkCode = <a className={linkClass} href={slug} target="_blank" >{menuItem.title}</a>
  //     }
  //     if(menuItem.slug == 'beelavender.com') {
  //       slug = '/'
  //     }
  //       return <li className={styles.navigationItem} key={i}>{linkCode}</li>
      
  //   })



    return (
  <nav className={'bar'} role="navigation">
  <ul className={styles.navigation}>
  {subnavLinks}

  </ul>


  </nav>
)
  
}
}



export default Subnavigation