import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.scss'


function slugify(string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

class Navigation extends React.Component {
  render() {
    //console.log(this.props.menu.menuItem);
    //const menuObject = this.props.menu.menuItem;
    const menu = this.props.menu
  //console.log(this.props.menu)
  //if(this.props.menu) {
    //const menuObject = menu.menu.menuItem;  
  //}
  
  //const path = globalHistory.location.pathname;


  

    //const menuObject = menu

    var listItemsMax = menu.map((menuItem,i) => {
      let slug = '/'

      var linkCode = ''
      var menuTitle = menuItem.title

      if (menuItem.pageItem) {
        slug = '/'+menuItem.pageItem.slug+'/'
        linkCode = <Link style={{color: 'black'}}activeStyle={{ fontWeight: "bold", textDecoration: "underline" }} to={slug}>{menuTitle}</Link>
      }
      if (menuItem.link) {
        slug = menuItem.link
        var linkClass = slugify(menuItem.title)

        if(styles[linkClass] != null){
          linkClass = styles[linkClass];
          menuTitle = <span className={styles.srOnly}>menuTitle</span>
        }


        linkCode = <a className={linkClass} href={slug} target="_blank" >{menuTitle}</a>
      }
      //let slug = '/'+menuItem.slug+'/'
      if(menuItem.slug == 'beelavender.com' || menuItem.title == 'Work') {
        slug = '/'
        linkCode = <Link style={{color: 'black'}}activeStyle={{ fontWeight: "bold", textDecoration: "underline" }} to={slug}>{menuItem.title}</Link>

      }
        return <li className={styles.navigationItem} key={i}>{linkCode}</li>
      
    })



  //console.log(menuObject)

//   menuItems = menuObject.map((menuListItem, i) => {
//    console.log(menuListItem)
//    return <li>Hello</li>
// })
  
  // menuItems = menuObject.map((menuListItem, i) => {
  //   let slug = ''
  //   if(menuListItem.slug != 'work') {
  //     slug = menuListItem.slug+'/'
  //   }
  //   return <li key={menuListItem.slug} className={'nav-item'}>
  //   <Link activeClassName={'active'} to={'/'+slug}>{menuListItem.title}</Link>
  //   </li>
  // })





        //console.log(data.allContentfulAlfaMenu.edges.node)
   // const menuItems = get(this.props, 'data.allContentfulAlfaMenu')


    return (
  <nav className={styles.navigationBar + ' section'} role="navigation">
  <Link to={`/`}>Formal Key</Link>
  <ul className={styles.navigation}>
    {listItemsMax}

  </ul>


  </nav>
)
  
}
}



export default Navigation