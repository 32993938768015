import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './project-listing.module.scss'

//import ReactFitText from 'react-fittext'


class projectListing extends React.Component {
  render() {
    
    if (!this.props.caseStudy.project) {
       return null;
    }

       const caseStudy = this.props.caseStudy;
       console.log(caseStudy);
       
       if(this.props.caseStudy.project) {
         const caseStudyProject = this.props.caseStudy.project;
          console.log(caseStudyProject[0].title);
        }
        




       var title = ''
       var description = ''
       var tileContent = ''
       var img = ''
       var client = ''
       var keyColour = ''
       var fixedImg = ''
       var fixedImgSource = ''
       var projectCategory = ''

       if(this.props.caseStudy) {
         title = <h2>{this.props.caseStudy.title}</h2>
       }

       if(this.props.caseStudy.client) {
        client = <span>{caseStudy.client.name}</span>
      }
      
      //get key colour

      if(this.props.caseStudy.project) {
         keyColour = this.props.caseStudy.project[0].keyColour
      }
      if(this.props.caseStudy.keyColour) {
         keyColour = this.props.caseStudy.keyColour
      }
if(this.props.caseStudy.featuredImage) {
  fixedImgSource = caseStudy.featuredImage.fixed
}
else if (this.props.caseStudy.project) {
  fixedImgSource = this.props.caseStudy.project[0].featuredImage.fixed
}
console.log(caseStudy.title)

console.log(fixedImgSource);

        if(fixedImgSource != '') {
//          console.log(caseStudy.title + ' has featured')
// 
//         img = <Img fluid={caseStudy.featuredImage.fluid} />
// 


        // else if(this.props.caseStudy.keyColour) {
        //    keyColour = this.props.caseStudy.keyColour
        // }
        // else {
        //    keyColour = '#EEEEEE'
        // }
         fixedImg = <Img backgroundColor={keyColour} fixed={fixedImgSource} />
//         console.log(fixedImg)
// 
        }





       //console.log(caseStudy.featuredImage.fluid)
if(this.props.caseStudy.project) {
  description = <div
          dangerouslySetInnerHTML={{
            __html: this.props.caseStudy.project[0].description.childMarkdownRemark.html,
          }}
        />
}
        if(this.props.caseStudy.description) {
              description = <div
                      dangerouslySetInnerHTML={{
                        __html: caseStudy.description.childMarkdownRemark.html,
                      }}
                    />
       }


       if(this.props.caseStudy) {
        //slug = this.props.caseStudy.slug
        if(this.props.caseStudy.project) {
          client = <span>{this.props.caseStudy.project[0].title}</span>
        }
        let link = `/`+this.props.caseStudy.slug+`/`
        tileContent = <Link to={link}>{client}{title}{description}</Link>
       }

    //    var displayProp = ''
    //    if(this.props.display) {
    //      displayProp = this.props.display
    //    }

    //    if (this.props.currentSlug && this.props.currentSlug != updateTableProps.tableProduct.slug) {
    //       return null;
    //    }


    // if(updateTableProps.title){ 
    //   title = updateTableProps.title;
    // }

    // if(client.clientLogo) {
    //     logo = <img className={"img-responsive"} alt={title} src={client.clientLogo ? client.clientLogo.file.url : null} />
    //     title = <span className={styles.srOnly}>{this.props.client.name}</span>
    // }
    // else if(updateTableProps.platformManufacturer) {
    //     logo = <img className={"img-responsive"} width={updateTableProps.platformManufacturer.logo ? updateTableProps.platformManufacturer.logo.file.details.image.width : null} height={updateTableProps.platformManufacturer.logo ? updateTableProps.platformManufacturer.logo.file.details.image.height : null} src={updateTableProps.platformManufacturer.logo ? updateTableProps.platformManufacturer.logo.file.url : null} />
    // }



return (

    <article style={{ background: '#'+keyColour }} className={styles.caseStudyTile}>{fixedImg}{tileContent}</article>
)
  }
}

export default projectListing

// <div className={styles.updateTableSection}>
// <h3>{logo}<span className={"sr-only"}>{title}</span></h3>
//   {table}

// </div>