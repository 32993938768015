import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

// Gatsby adds 'c' to entity id if it starts with a number.

function fixId(id) {
  if (id.startsWith("c")) {
    return id.slice(1)
  }
  else return id
}

export const ContentfulFixedAsset = ({ contentfulId }) => {
  //console.log("id is "+contentfulId)
  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset {
        edges {
          node {
            fluid(maxWidth: 2560) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
            contentful_id
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  `)

  const image = data.allContentfulAsset.edges.find(
    edge => edge.node.contentful_id === fixId(contentfulId)
  )

  if(image.node.file.url.substr(image.node.file.url.length - 3) == 'svg') {
    return <figure><img src={image.node.file.url} width={image.node.file.details.image.width} height={image.node.file.details.image.height}/></figure>
  }
  else {
    return <Img itemProp={`image`} style={{ width: '100%' }} fluid={image.node.fluid}/>
  }

  
}
export default ContentfulFixedAsset