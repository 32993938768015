import React from 'react'
import './sassline/style.scss'
import './base.scss'
import Container from './container'
import Navigation from './navigation'
import { useStaticQuery, graphql } from "gatsby"


const Template = ({ children }) => {
  		const data = useStaticQuery(graphql`
  		  query SiteTitleQuery {
  		    site {
  		      siteMetadata {
  		        title
  		      }
  		    }
  allContentfulMenu(filter: {menuTitle: {eq: "Main Menu"}}) {
    edges {
      node {
        menuItemList {
          title
          pageItem {
            slug
          }
          link
        }
      }
    }
  }
  		
  		  }
  		`)
  		const menu = data.allContentfulMenu.edges[0].node.menuItemList

  		var menuItems = ''


  		//console.log(menu)


    return (
      <Container>
        <Navigation menu = {menu}/>
        <div id={"baseline"} className={""}>
        {children}
        </div>
       <footer className={"section typeset"}>
                 © {new Date().getFullYear()}
               </footer>
      </Container>
    )
  }


export default Template
