import React from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'

import heroStyles from '../components/hero.module.scss'

import sectionStyles from '../components/section-styles.module.scss'

import RichTextRenderer from '../components/rich-text-renderer'

import Subnavigation from '../components/subnavigation'

import Flickity from "react-flickity-component";

import Hero from '../components/hero'


class PageSectionContent extends React.Component { 
   render(){
 const fields = this.props.fields
 const columnClass = this.props.columnClass
     //console.log(fields);
    let contentClass = ''
    let sectionClass = ''
     if(fields.sectionClass != null){
      contentClass = fields.sectionClass
     }
     if(this.props.columnClass != null && this.props.columnClass != '') {
      
      contentClass = this.props.columnClass
      
      if(fields.sectionClass != null){
        contentClass += ' '
       contentClass += fields.sectionClass
      }
      

      



     }


 return (
  <div className={contentClass} >
              <RichTextRenderer articleJSON={fields.sectionContentContent.json} />
</div>
)
  }
}




class PageSection extends React.Component { 
  render(){
    const fields = this.props.fields
    console.log(fields)
    let columnClass = 'column'
    var sectionClass = 'section typeset'
    var pageContentRender

    if(fields.gridLayout) {
      //console.log(fields.gridLayout)
      if(fields.gridLayout == 'Four Columns') {
        columnClass = 'column column--quad'
      }
      if(fields.gridLayout == 'Two Columns') {
        columnClass = 'column column--duo'
      }
      if(fields.gridLayout == 'Three Columns') {
        columnClass = 'column column--trio'
      }
    }
    
    if(fields.sectionClass != null){
      //productSlug = this.props.product.replace(/-|\s/g,"")
      if(sectionStyles[fields.sectionClass] != null){
        //productSlug = styles[productSlug];
        sectionClass = sectionClass + ' ' + sectionStyles[fields.sectionClass]
      }
      else {
        sectionClass = sectionClass + ' ' + fields.sectionClass
      }
    }
    
    // if(fields.sectionClass) {
    //   if(styles[productSlug] != null){
    //     
    //   }
    //    sectionClass = sectionClass + ' ' + fields.sectionClass
    // }

    if(fields.pageContent){
      pageContentRender = fields.pageContent.map((section,i) => {
        let fields = section;

          
          return <PageSectionContent key={section.title} columnClass={columnClass} fields={fields}/>
        
      })

    }
    //var imageClass = ''
    if (!this.props.fields) {
       return null;
    }
    return(
       <React.Fragment>
<div className={sectionClass}>{pageContentRender}</div>
        </React.Fragment>
      )
  }
}


class PageTemplate extends React.Component {
  render() {
    const page = get(this.props, 'data.contentfulPage')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const allProjects = get(this.props, 'data.allContentfulProject.edges')
    const allPages = get(this.props, 'data.allContentfulPage.edges')
    const slideshow = get(this.props, 'data.contentfulSlideshow.slide')

    const pageSections = get(this.props, 'data.contentfulPage.pageLayoutContent')

    var pageSectionsRender = ''
    var heroImage = ''

    if(pageSections) {
      //console.log(pageSections);
      pageSectionsRender = pageSections.map((section,i) => {
        let fields = section;
          
          return <PageSection key={section.title}fields={fields}/>
        
      })
    }


    let subnavigationExists = 'no'

    var subnavigationElement = ''

    var nextProject = ''
    var previousProject = ''
    var nextPage = ''
    var previousPage = ''

    if(allProjects && page.project){
      nextProject = allProjects.map((project,i) => {
        if(project.node.slug == page.project[0].slug && project.next !== null) {
          let slug = `/${project.next.slug}/`
          return <p key={slug} className={heroStyles.next}><Link  to={slug}><span>Next:</span> {project.next.title} →</Link></p>
        }
      })
      previousProject = allProjects.map((project,i) => {
        if(project.node.slug == page.project[0].slug && project.previous !== null) {
          let slug = `/${project.previous.slug}/`
          return <p key={slug} className={heroStyles.previous}><Link to={slug}>← {project.previous.title} <span>:Previous</span> </Link></p>
        }
      })
    }
    
    if(allPages && page && page.project){
      nextPage = allPages.map((arrayPage,i) => {
        if(arrayPage.node.slug == page.slug && arrayPage.next !== null && arrayPage.next.project !== null) {
          let slug = `/${arrayPage.next.slug}/`
          return <li key={slug} className={heroStyles.next}><Link  to={slug}><span>Next:</span> {arrayPage.next.title} →</Link></li>
        }
      })
      previousPage = allPages.map((arrayPage,i) => {
        if(arrayPage.node.slug == page.slug && arrayPage.previous !== null) {
          let slug = `/${arrayPage.previous.slug}/`
          return <li key={slug} className={heroStyles.previous}><Link to={slug}>← {arrayPage.previous.title} <span>:Previous</span> </Link></li>
        }
      })
    }

    if(allProjects && page.project) {
      heroImage = allProjects.map((project,i) => {
        if(project.node.slug == page.project[0].slug) {
          console.log(project)
          //return <p>{project.node.slug}</p>
          return <Img fluid={project.node.featuredImage.fluid} />
        }
      })
    }
    if(page.featuredImage){
      console.log('page has a featured image');
      heroImage = <Img fluid={page.featuredImage.fluid} />
    }



    const flickityOptions = {
      cellSelector: '.gatsby-image-wrapper',
        adaptiveHeight: true,
        wrapAround: true
    }



   // console.log(slideshow)

    if(page.project) {
      console.log("project slug is "+page.project[0].slug)
      console.log("containedPages is "+page.project[0].containedPages.length)
      if(page.project[0].containedPages.length > 1) {
        subnavigationExists = 'yes' 
        subnavigationElement = <Subnavigation projectTitle={page.project[0].title} projectSlug={page.project[0].slug} projectPages={page.project[0].containedPages} currentPageSlug={page.slug} />
      }
    }

    

    return (
      <Layout location={this.props.location}>
       {heroImage}
        <div className={"section typeset"}>
          <Helmet title={`${page.title} – ${siteTitle}`} >
          <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" /> 

          </Helmet>
         
         
          <div>
          {/*subnavigationElement*/}
            <h1>{page.title}</h1>

                       
                        
                        <nav className={heroStyles.previousNext}>
                        <ul>
                        {previousPage}
                        {nextPage}
                        </ul>
                        </nav>
           
          

            <RichTextRenderer articleJSON={page.content.json} />

          </div>
          
        </div>
        {pageSectionsRender}
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query pageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
allContentfulPage {
  edges {
    node {
      title
      id
      slug
    }
    next {
      slug
      title
      project {
        title
      }
    }
    previous {
      slug
      title
      project {
        title
      }
    }
  }
}
    contentfulPage(slug: { eq: $slug }) {
     slug
     project {
      slug
      title
       containedPages {
         slug
         title
       }
     }
     content {
       json
     }
     title
     featuredImage {
       fluid(maxWidth: 1680, background: "rgb:EEEEEE", quality: 80, maxHeight: 880 ) {
         ...GatsbyContentfulFluid_withWebp
       }
     }
     pageLayoutContent {
       id
       pageContent {
         sectionContentContent {
           json
         }
         sectionClass
         title
         
       }
       sectionClass
       gridLayout
       title
     }
    }
    allContentfulProject {
      edges {
        node {
          title
          slug
          featuredImage {
            fluid(maxWidth: 1680, background: "rgb:EEEEEE", quality: 80, maxHeight: 880 ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        next {
          slug
          title
        }
        previous {
          slug
          title
        }
      }
    }
  }
`
