import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styles from './article-preview.module.scss'

import Flickity from 'react-flickity-component'

import carouselStyles from './flickity.scss'

import FixedAsset from '../components/contentful-fixed-asset'

 
const flickityOptions = {
    initialIndex: 2
}
 

class Carousel extends React.Component {
  render() {
const carouselSlides = this.props.slides
const carouselFields = this.props.fields

console.log(carouselFields.slideshowTitle["en-GB"]);

var pageDotsBool = false
var prevNextBool = true
var autoPlayBool = false
var draggableBool = true

if(this.props.fields.pageDots) {
  pageDotsBool = carouselFields.pageDots["en-GB"]
}
if(this.props.fields.previousNextButtons) {
  prevNextBool = carouselFields.previousNextButtons["en-GB"]
}
if(this.props.fields.autoplay) {
  autoPlayBool = carouselFields.autoplay["en-GB"]
}
if(this.props.fields.draggable) {
  draggableBool = carouselFields.draggable["en-GB"]
  console.log(carouselFields.slideshowTitle["en-GB"] + " draggableBool is "+draggableBool)

}


console.log(carouselFields.slideshowTitle["en-GB"] + " pageDots is "+pageDotsBool)
console.log(carouselFields.slideshowTitle["en-GB"] + " prevNextBool is "+prevNextBool)
//console.log(carouselFields.pageDots["en-GB"])

//console.log(carouselSlides["en-GB"])


    const flickityOptions = {
      cellSelector: '.gatsby-image-wrapper',
        adaptiveHeight: true,
        wrapAround: true,
        selectedAttraction: 0.1,
        friction: 0.75,
        draggable: draggableBool,
        autoPlay: autoPlayBool,
        prevNextButtons: prevNextBool,
        pageDots: pageDotsBool

    }



   
    return(
    <Flickity
      className={'carousel'} // default ''
      elementType={'div'} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={false} // default false
      reloadOnUpdate={true} // default false
      static={true} // default false
    >
    {carouselSlides['en-GB'].map(( node ) => {
      return (
               <FixedAsset key={node.sys.id} contentfulId={node.sys.id} />
      )
    })}
    </Flickity>
)
  }
}


export default Carousel