import React from 'react';
import { Link } from 'gatsby';


// import { OutboundLink } from 'gatsby-plugin-google-analytics';



import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import FixedAsset from '../components/contentful-fixed-asset'



import styles from './rich-text-renderer.module.scss';

import Carousel from '../components/carousel'

// const EmbeddedImage = ({ fields }) => (
//   <figure itemProp={`image`} class={fields.description ? fields.description[`en-US`] : null} itemScope={true} itemtype={`https://schema.org/ImageObject`}><img className={styles.imgResponsive} width={fields.file[`en-US`].details.image.width} height={fields.file[`en-US`].details.image.height} src={fields.file[`en-US`].url} itemProp={'contentUrl url'} alt={fields.title[`en-US`]}/></figure> 
// );

// class EmbeddedImage extends React.Component {
//   render() {
//     const fields = this.props.fields
//     var class = ''

//   return (
// <figure itemProp={`image`} class={fields.description ? fields.description[`en-US`] : null} itemScope={true} itemtype={`https://schema.org/ImageObject`}><img className={styles.imgResponsive} width={fields.file[`en-US`].details.image.width} height={fields.file[`en-US`].details.image.height} src={fields.file[`en-US`].url} itemProp={'contentUrl url'} alt={fields.title[`en-US`]}/></figure> 
//     )
//   }
// }

class EmbeddedImage extends React.Component { 
  render(){
    const fields = this.props.fields
    var imageClass = ''
    //console.log(fields);  

    if(fields.file != null && fields.file[`en-GB`].contentType.startsWith("video")){
      console.log('this is a video!')
      return (
        <video autoplay="true" loop="true" muted="true" playsinline="true" style={{ width: `100%` }}>
        <source src={fields.file[`en-GB`].url} type={fields.file[`en-GB`].contentType} />
        </video>
        );
    }
    if (!this.props.fields) {
       return null;
    }
    

    const sys = this.props.sys
    var imageClass = ''
    if (!this.props.fields) {
       return null;
    }
    var altText = fields.title[`en-US`]

    if(this.props.sys) {
      //console.log ("case study id is "+this.props.sys.id)
      return <FixedAsset contentfulId={this.props.sys.id} />
    }

    if(this.props.fields && this.props.fields.description) {
      //imageClass = fields.description[`en-US`]
      altText = fields.description[`en-US`]
      if(styles[altText]) {
        imageClass = styles[altText]
        altText = fields.title[`en-US`]
      }
    }
    return(
       <React.Fragment>
       <figure itemProp={`image`} class={imageClass} itemScope={true} itemtype={`https://schema.org/ImageObject`}><img className={styles.imgResponsive} width={fields.file[`en-US`].details.image.width} height={fields.file[`en-US`].details.image.height} src={fields.file[`en-US`].url} itemProp={'contentUrl url'} alt={altText}/></figure> 
        </React.Fragment>
      )
  }
}
class InlineEntry extends React.Component {
  render() {
    const fields = this.props.fields
    let icon = this.props.fields.icon[`en-US`].fields.file[`en-US`]
    const content = this.props.content
    //console.log(fields)
    //console.log(this.props.content)
    var linkReact = <img width={icon.details.image.width} height={icon.details.image.height} src={icon.url} alt={content}/>


    return (
      
      <span> {linkReact} </span>
      
      )
  }
}


class EntryHyperlink extends React.Component {
  render() {
    const fields = this.props.fields
    const content = this.props.content
    //if(this.props.fields) {
      //console.log(fields)
      //var link = fields.slug[`en-US`]  
    //}
    
    var pageSlug = '';
    var linkTitle = ''

    if(this.props.content) {
      linkTitle = this.props.content.value
    }
    else {
      linkTitle = fields.title[`en-US`]
    }
    if(this.props.fields) {
      if(fields.productReference) {
        pageSlug += `/${fields.productReference['en-US'].fields.slug['en-US']}`;
      }

      if(fields.articleCategoryTopic) {
        pageSlug += `/${fields.articleCategoryTopic['en-US'].fields.slug['en-US']}`;
      }
      if(fields.pageTopic) {
        pageSlug += `/${fields.pageTopic['en-US'].fields.slug['en-US']}`;
      }
      
      if(fields.slug) {
        pageSlug += `/${fields.slug['en-US']}/`;
      }
    }
    var linkReact = <Link to={pageSlug}>{linkTitle}</Link>

    return ( linkReact
      
      )
  }
}

class RegularHyperlink extends React.Component {
  render() {
    const fields = this.props.fields.uri
    const content = this.props.content

    const linkMatchValue = "https://support.youview.com"
    

    var n = fields.startsWith("https://support.youview.com");

    var linkReact = ''

      //console.log("n is "+n)


    if(fields.startsWith("https://support.youview.com")) {
      var newLink = fields.substr(linkMatchValue.length)
      linkReact = <Link to={newLink}>{content}</Link>
      
    }
    // else {
    //   linkReact = <OutboundLink href={fields}>{content}</OutboundLink>
    // }
    return ( linkReact )
  }
}


class Paragraph extends React.Component {
  render() {
    const fields = this.props.children
    console.log(fields[0]);
    //console.log("type is " + typeof fields[0][1]);
    console.log("length is " + typeof fields[0]);
    console.log("Array: " + Array.isArray(fields[0]));
    //if(fields[0][1] == )
    
    if(!this.props.children) {
      return null    
    }
    if(Array.isArray(fields[0])) {
      if(fields[0][1].length == 0) {
        return null  
      }
      
    }
    
    var paragraph = <p>{fields}</p>

    return ( 
      paragraph
      )
  }
}







class EmbeddedEntry extends React.Component {
  render() {
    const entryData = this.props.entryData


    //const fields = this.props.entryData.fields;



    if(entryData.sys.contentType.sys.id == "slideshow") {
      //console.log("I think this is the "+entryData.fields.slideshowTitle['en-GB'])
      console.log(entryData)
      console.log(entryData.fields.slide)

      return (
        <Carousel fields={entryData.fields} slides={entryData.fields.slide}/>

        )
    }
    // console.log("I am an embedded entry")
     //console.log(entryData.sys.contentType.sys.id)
    
    // var title = ''
    // var content = ''
    // if(fields.title) {
    //   title = fields.title
    // }
    // if(fields.stepContent) {
    //   content = fields.stepContent
    // }
      
    


    return (
      
<h2>Slideshow?</h2>
      
    )
      }
    }

class RichTextRenderer extends React.Component {
  render() {
    const articleJSON = this.props.articleJSON;
    //console.log(articleJSON)
    var emptyArticle = '';
    if(articleJSON == '' || !this.props.articleJSON) {
      return null;
    }
    var containerClass = styles.article
    var itemProp = false
    if(this.props.containerClass) {
      containerClass = this.props.containerClass
      if(styles[containerClass]){
        containerClass = styles[containerClass]
      }
      containerClass = [styles.article, containerClass].join(' ')
    }
    if(this.props.itemProp) {
      itemProp = this.props.itemProp
    } 

      const options = {
       renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const fields = node.data.target.fields;
             const sys = node.data.target.sys;
             return <EmbeddedImage sys={sys} fields={fields}/>

      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const fields = node.data.target.fields;
        //const content = node.data.target.fields;
        const content = node.data.target.sys.contentType.sys.id;
        if(content == 'supportSiteInlineImage') {
          return <InlineEntry content={content} fields={fields}/>
        }
        else {
          return null
        }
        //return fields.id
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const entryData = node.data.target;
        //console.log(node);

        //return fields.id
        return <EmbeddedEntry entryData={entryData}/>

      },
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        const fields = node.data.target.fields;
        const content = node.content[0];
        if(node.data.target.sys.contentType != null) {
          //console.log(node.data.target.sys.contentType)  
        }
        else {
          //console.log("no contentType!")
        }
        var nodeType = ''
        
        if (typeof(node.data.target.sys.contentType) !== 'undefined') {
          nodeType = node.data.target.sys.contentType.sys.id
        }
         // if(node.data.target.sys.contentType.sys.id) {
         //  nodeType = node.data.target.sys.contentType.sys.id
         // }
         // console.log(content)
        //return fields.id
        if(nodeType == 'supportSiteArticle' || nodeType == 'supportSitePage') {
          return <EntryHyperlink content={content} fields={fields}/>
        }
        else {
          return null
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        const fields = node;
        const content = node.content[0].value
        //console.log(node.content[0]);
          return <RegularHyperlink content={content} fields={node.data}/>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    },
    
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };



  //console.log(articleJSON)

    


return (
  
    <div itemProp={itemProp}>
{documentToReactComponents(articleJSON, options)}              
    </div>
)
  }
}

export default RichTextRenderer
