import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'

import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'

import Img from 'gatsby-image'

import ProjectListing from '../components/project-listing'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    //const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    //const [author] = get(this, 'props.data.allContentfulPerson.edges')
    const menuItems = get(this, 'props.data.allContentfulMenu.edges.node')
    const projects = get(this, 'props.data.allContentfulProject.edges')
    const pages = get(this, 'props.data.allContentfulPage.edges')
    

    var projectTiles = ''
    var pageTiles = ''

    projectTiles = projects.map((project, i) => {
      //console.log(client.node)
      return  <div className={"column column--duo"} key={project.node.id}><ProjectListing caseStudy={project.node} /></div>
    })
    
    pageTiles = pages.map((page, i) => {
      //console.log(client.node)
      return  <div className={"column column--duo"} key={page.node.id}><ProjectListing caseStudy={page.node} /></div>
    })
    

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={siteTitle} />
         
          <div className="section typeset">
          <div className={'column'}>
            <h2>Recent projects</h2>
            </div>
            </div>
             <div className="section typeset">
             {pageTiles}
             
          
            
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  allContentfulMenu(filter: {menuTitle: {eq: "Main Menu"}}) {
    edges {
      node {
        menuItemList {
          title
        }
      }
    }
  }
  allContentfulProject {
    edges {
      node {
        id
        title
        slug
        keyColour
        description {
          childMarkdownRemark {
            html
          }
        }
        featuredImage {
          fluid(maxWidth: 650, background: "rgb:EEEEEE", quality: 80, maxHeight: 380 ) {
            ...GatsbyContentfulFluid_withWebp
          }
          fixed(width: 580, quality: 80, height: 380){
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
        }
      }
    }
  }
  allContentfulPage {
    edges {
      node {
        title
        project {
          title
          keyColour
          description {
            childMarkdownRemark {
              html
            }
          }
          featuredImage {
            fluid(maxWidth: 650, background: "rgb:EEEEEE", quality: 80, maxHeight: 380 ) {
              ...GatsbyContentfulFluid_withWebp
            }
            fixed(width: 580, quality: 80, height: 380){
              ...GatsbyContentfulFixed_withWebp_noBase64
            }
          }
        }
        id
        slug
        keyColour
        description {
          childMarkdownRemark {
            html
          }
        }
        featuredImage {
          fluid(maxWidth: 650, background: "rgb:EEEEEE", quality: 80, maxHeight: 380 ) {
            ...GatsbyContentfulFluid_withWebp
          }
          fixed(width: 580, quality: 80, height: 380){
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
        }
      }
    }
  }
  }
`
